import { useEECEvents } from 'analytics/hooks/useEECEvents/useEECEvents'
import { useNavigation } from 'hooks/useNavigation/useNavigation'
import { useMasterData } from 'master-data/hooks/useMasterData/useMasterData'
import { useResponsive } from 'responsive/hooks/useResponsive/useResponsive'

import { PAGE_TYPE_BOLSA } from '../constants/constants'
import { useCartDispatch, useCartState } from '../context/CartProvider'
import { getBagItems } from '../services/queries'
import type { ControllerType } from '../types/ControllerType'
import { refresh } from '../utils/controller'
import { add } from '../utils/controller/add'
import { useCartFetcher } from './useCartFetcher'
import { useErrorManagement } from './useErrorManagement'
import { useNotifications } from './useNotifications'

export interface CartDataType {
	findTotalItemsByUser: {
		totalItems: number
		merged: boolean
	}
}

export const useCartController = (): ControllerType => {
	const cartState = useCartState()
	const {
		pageType,
		country: { countryISO },
	} = useMasterData()

	const { showShoppingCart, showPreview, totalItems } = cartState

	const { addErrorManagement } = useErrorManagement()
	const dispatch = useCartDispatch()
	const { navigate } = useNavigation()
	const { addItemNotification } = useNotifications()
	const { isLargeOrGreater } = useResponsive()
	const { sendAddToCart } = useEECEvents()

	const { globalMutate, fetcher } = useCartFetcher<CartDataType, undefined>({
		query: getBagItems,
		options: {
			revalidateOnMount: false,
		},
	})

	const handleToggle = (): boolean => {
		if (!showShoppingCart) {
			dispatch({ showPreview: !showPreview })
			return true
		}
		return false
	}

	const handleOpen = () => {
		if (!showPreview && !showShoppingCart) {
			dispatch({ showPreview: true })
		}
	}

	const handleClose = () => {
		dispatch({ showPreview: false })
	}

	const addParams = {
		showShoppingCart: pageType === PAGE_TYPE_BOLSA,
		isDesktop: isLargeOrGreater,
		countryISO,
		cartState,
		addErrorManagement,
		dispatch,
		fetcher,
		addItemNotification,
		globalMutate,
		sendAddToCart,
	}

	return {
		status: !!showPreview,
		toggle: handleToggle,
		open: handleOpen,
		close: handleClose,
		totalItems: totalItems || 0,
		refresh: () => refresh(navigate),
		add: add(addParams),
		addWithoutNotification: add({
			...addParams,
			addItemNotification: () => {},
		}),
	}
}
