import { getAllFavorites } from 'favorite/services/getAllFavorites'

import type { FavoriteProductResponse } from '../../types/types'

export const getFavorites = async (
	token?: string
): Promise<FavoriteProductResponse[]> => {
	const response = await getAllFavorites({ token })

	if (!response.ok) {
		throw response
	}
	return response.json()
}
