import { featureFlagKeys } from 'feature-flags/constants/featureFlags'
import { useFeatureFlag } from 'feature-flags/hooks/useFeatureFlag/useFeatureFlag'

export function useShowFavorites(): boolean {
	const { variables, enabled } = useFeatureFlag(
		featureFlagKeys.NonCriticalFeatures
	)

	return enabled && Boolean(variables?.favorites ?? true)
}
