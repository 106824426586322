import { IconDone } from 'icons/components/IconDone'
import { IconErrorL } from 'icons/components/IconErrorL'
import { useLabels } from 'labels/hooks/useLabels/useLabels'
import { SnackbarPosition } from 'notifications/constants/constants'
import { useNotificationsContext } from 'notifications/hooks/useNotificationsContext'
import type { MouseEvent } from 'react'

import {
	ADD_NOTIFICATION_STORE_TIME,
	ADD_NOTIFICATION_TIME,
} from '../constants/constants'
import { useAnalytics } from './useAnalytics'

export const useNotifications = () => {
	const { addNotification, removeNotification } = useNotificationsContext()
	const { t } = useLabels()
	const { sendBagUpdateEvent } = useAnalytics()

	return {
		removeNotification,
		addItemNotification: () =>
			addNotification({
				snackbarShow: false,
				id: 'addItemNotification',
				title: t('snackbar.addToBag.confirmation.text'),
				delay: ADD_NOTIFICATION_STORE_TIME,
				icon: <IconDone color='content-inverse' />,
				position: SnackbarPosition.TOP,
			}),
		addItemNotificationError: () =>
			addNotification({
				id: 'addItemNotificationError',
				title: t('snackbar.addToBag.error.text'),
				delay: ADD_NOTIFICATION_STORE_TIME,
				icon: <IconErrorL color='content-inverse' />,
				position: SnackbarPosition.BOTTOM,
			}),
		itemWithoutStockNotification: () =>
			addNotification({
				id: 'itemWithoutStockNotification',
				title: t('shoppingBag.error.noStock.title'),
				subtitle: t('shoppingBag.error.noStock.bodycopy'),
				delay: ADD_NOTIFICATION_STORE_TIME,
				icon: <IconErrorL color='content-inverse' />,
				position: SnackbarPosition.TOP,
			}),
		updateNotification: () => {
			sendBagUpdateEvent()
			addNotification({
				id: 'updateNotification',
				title: t('shoppingBag.tooltip.updatedBag.title'),
				subtitle: t('shoppingBag.tooltip.updatedBag.bodycopy'),
				delay: ADD_NOTIFICATION_TIME,
				position: SnackbarPosition.TOP,
			})
		},
		itemWithParamErrorNotification: () =>
			addNotification({
				id: 'itemWithParamErrorNotification',
				title: t('shoppingBag.error.connection.title'),
				subtitle: t('shoppingBag.error.connection.bodycopy'),
				delay: ADD_NOTIFICATION_STORE_TIME,
				icon: <IconErrorL color='content-inverse' />,
				position: SnackbarPosition.TOP,
			}),
		deletedItemNotification: ({
			onClick,
		}: {
			onClick: (e: MouseEvent<HTMLButtonElement>) => void
		}) =>
			addNotification({
				onClick,
				id: 'deletedItemNotification',
				title: t('shoppingBag.deletedItem.snackbar.title'),
				delay: ADD_NOTIFICATION_STORE_TIME,
				buttonText: t('snackbar.undo.button'),
				position: SnackbarPosition.BOTTOM,
			}),
		editedItemErrorNotification: ({
			onClick,
		}: {
			onClick: (e: MouseEvent<HTMLButtonElement>) => void
		}) =>
			addNotification({
				onClick,
				id: 'editedItemErrorNotification',
				title: t('snackbar.edit.error.text'),
				delay: ADD_NOTIFICATION_STORE_TIME,
				buttonText: t('snackbar.edit.error.button'),
				position: SnackbarPosition.BOTTOM,
			}),
	}
}
