'use client'

import { conversion } from 'country-configuration/currency'
import { MODAL_TYPE } from 'fukku/Modal'
import { useMasterData } from 'master-data/hooks/useMasterData/useMasterData'
import type { ReactNode } from 'react'
import {
	type Dispatch,
	createContext,
	useCallback,
	useContext,
	useMemo,
	useState,
} from 'react'

import { PAGE_TYPE_BOLSA, STOCK } from '../constants/constants'
import { type PurchaseType } from '../services/graphql.types'

export interface InitialState {
	showPreview: boolean | 'auto'
	openedLimitWarningModal: boolean
	visibleItemErrorBanner: boolean
	visibleLimitWarningBanner: boolean
	cartMaxAmount: string
	showRefundPartners: boolean
	openedAddNoStockModal: boolean
	openedLoginModal: boolean
	openedStockModal: boolean
	stockModalState: (typeof STOCK)[keyof typeof STOCK] | null
	stockModalType: MODAL_TYPE | null
	stockOneItemOnly: boolean | null
	stockCart: PurchaseType | null
	totalItems?: number | null
	preventClose?: boolean | null
	status?: boolean
}

const DEFAULT_INITIAL_STATE: InitialState = {
	showPreview: false,
	openedLimitWarningModal: false,
	visibleItemErrorBanner: false,
	visibleLimitWarningBanner: false,
	cartMaxAmount: '',
	showRefundPartners: false,
	openedAddNoStockModal: false,
	openedLoginModal: false,
	openedStockModal: false,
	stockModalState: null,
	stockModalType: null,
	stockOneItemOnly: null,
	stockCart: null,
	totalItems: null,
	preventClose: false,
}

export type CartStateContextType = InitialState & { showShoppingCart: boolean }

export interface CartProviderProps {
	children: ReactNode
	initialState?: Partial<CartStateContextType>
}

export type CartDispatch = Dispatch<Partial<InitialState>>

export const CartStateContext = createContext<CartStateContextType | undefined>(
	undefined
)

const CartDispatchContext = createContext<
	((newState: Partial<CartStateContextType>) => void) | undefined
>(undefined)

export const CartProvider = ({
	children,
	initialState = {},
}: CartProviderProps) => {
	const { country, pageType } = useMasterData()

	const defaultInitialState = {
		...DEFAULT_INITIAL_STATE,
		cartMaxAmount: conversion(country.shoppingCart.cartMaxAmount, country)
			.price,
	}

	const [state, setState] = useState<InitialState>({
		...defaultInitialState,
		...initialState,
	})

	const dispatch = useCallback((newState: Partial<CartStateContextType>) => {
		setState((prevState) => ({ ...prevState, ...newState }))
	}, [])

	const value = useMemo(
		() => ({ ...state, showShoppingCart: pageType === PAGE_TYPE_BOLSA }),
		[state, pageType]
	)

	return (
		<CartStateContext.Provider value={value}>
			<CartDispatchContext.Provider value={dispatch}>
				{children}
			</CartDispatchContext.Provider>
		</CartStateContext.Provider>
	)
}

export function useCartState(): CartStateContextType {
	const context = useContext(CartStateContext)
	if (context === undefined) {
		throw new Error('useCartState must be used within a CartProvider')
	}
	return context
}

export function useCartDispatch() {
	const context = useContext(CartDispatchContext)
	if (context === undefined) {
		throw new Error('useCartDispatch must be used within a CartProvider')
	}
	return context
}
