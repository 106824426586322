'use client'

import { addFavorites } from 'favorite/services/addFavorites'
import { useMasterData } from 'master-data/hooks/useMasterData/useMasterData'
import { useCallback } from 'react'
import { useSession } from 'session/src/hooks/useSession'

import { getCacheFavorites } from '../utils/getCacheFavorites'

export const useSyncFavorites = () => {
	const {
		country: { countryISO, languageISO },
	} = useMasterData()
	const { token } = useSession()

	const syncFavorites = useCallback(async (): Promise<void> => {
		const favorites = await getCacheFavorites()
		if (!favorites || favorites.length === 0) {
			return
		}

		const products = favorites.map(({ ref, color }) => ({ ref, color }))

		const response = await addFavorites({
			products,
			countryISO,
			languageISO,
			token,
		})

		if (!response.ok) {
			console.error('Failed to syncronize favorites')
		}
	}, [token, countryISO, languageISO])

	return { syncFavorites }
}
