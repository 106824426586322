'use client'

import { useInitializeFavorites } from 'favorite/hooks/useInitializeFavorites'
import type {
	FavoriteProductResponse,
	FavoritesState,
} from 'favorite/types/types'
import { useShowFavorites } from 'favorite/utils/showFavorites/client/useShowFavorites'
import {
	type Dispatch,
	createContext,
	useCallback,
	useContext,
	useRef,
	useState,
} from 'react'
import { useSession } from 'session/src/hooks/useSession'

interface FavoritesProviderProps {
	children: React.ReactNode
}

const FavoritesContext = createContext<FavoritesState | undefined>(undefined)

const FavoriteDispatchContext = createContext<
	Dispatch<Partial<FavoritesState>> | undefined
>(undefined)

function FavoritesProvider({
	children,
}: Readonly<FavoritesProviderProps>): React.ReactNode {
	const { isReady } = useSession()

	const enabled = useShowFavorites()

	const hasInitialized = useRef(false)
	const DEFAULT_INITIAL_STATE: FavoritesState = {
		favorites: null,
		isLoading: true,
		hasInitialized,
		enabled,
	}

	const [state, setState] = useState({
		...DEFAULT_INITIAL_STATE,
	})

	const favoritesEnabled = isReady && enabled

	const dispatch = useCallback(
		(newState: Partial<FavoritesState>) =>
			setState((prevState) => ({ ...prevState, ...newState })),
		[]
	)

	useInitializeFavorites({
		favoritesEnabled,
		hasInitialized,
		onInitialitze: (favorites: FavoriteProductResponse[]) =>
			dispatch({ ...state, favorites, isLoading: false }),
	})

	return (
		<FavoritesContext.Provider value={state}>
			<FavoriteDispatchContext.Provider value={dispatch}>
				{children}
			</FavoriteDispatchContext.Provider>
		</FavoritesContext.Provider>
	)
}

const useFavorites = () => {
	const context = useContext(FavoritesContext)
	if (context === undefined) {
		throw new Error('useFavorites must be used within a FavoritesProvider')
	}
	return context
}

const useDispatchFavorites = () => {
	const context = useContext(FavoriteDispatchContext)
	if (context === undefined) {
		throw new Error(
			'useDispatchFavorites must be used within a FavoritesProvider'
		)
	}
	return context
}

export {
	FavoritesContext,
	FavoritesProvider,
	useDispatchFavorites,
	useFavorites,
}
